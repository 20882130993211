import { useState } from 'react';

const NavItem = (props) => {
	const [ hovering, setHovering ] = useState(false);

	const styles = {
		nav_item: {
			margin: '0rem 1.25rem',
			textTransform: 'uppercase',
			cursor: 'pointer',
			fontWeight: '600',
			fontSize: '1rem',
			color: hovering ? '#d6d6d6' : '#fff',
			transform: hovering ? 'scale(1.1)' : '',
			transition: '0.3s'
		},
		nav_dropdown: {
			display: hovering ? 'block' : 'none',
			position: 'absolute',
			margin: '0.5rem 0.25rem',
			fontWeight: '600',
			fontSize: '1rem',
			textTransform: 'uppercase',
			color: 'white',
			cursor: 'pointer',
		}
	};

	return (
		<li
			onMouseEnter={() => setHovering(true)}
			onMouseLeave={() => {
				setHovering(false);
			}}
			onClick={props.onClick}
			className={props.className}
		>
			<p style={styles.nav_item} onClick={() => window.location= props.titleLink}>{props.title}</p>
			<div style={styles.nav_dropdown}>
				{props.children}
			</div>
		</li>
	);
};

export default NavItem;
