import React from "react";
import CookieConsent from "react-cookie-consent";
import Link from "next/link";

const Consent = ({ setCookieAcceptance }) => {
  return (
    <React.Fragment>
      <CookieConsent
        location="bottom"
        buttonText="I Accept"
        cookieName="priceErrorsCookie"
        buttonStyle={{
          backgroundColor: "green",
          fontWeight: "bolder",
          color: "white",
        }}
        declineButtonStyle={{ fontWeight: "bolder" }}
        expires={365}
        debug={true}
        onAccept={() => {
          setCookieAcceptance(true);
        }}
      >
        We use our own cookies on our website to enhance your experience,
        analyze our traffic, and for security and marketing. For more info, see
        our <Link href="/policies/tos">Terms of Service</Link> and{" "}
        <Link href="/policies/privacy">Private Policy</Link>.
      </CookieConsent>
    </React.Fragment>
  );
};
export default Consent;
