import useSWR from 'swr';

const fetcher = async (url) => {
    // return fetch(url).then(r => r.json());
    const res = await fetch(url);

    if(!res.ok) {
    	const error = new Error('Failed to fetch user');
    	error.info = await res.json();
    	error.status = res.status;
    	throw error;
    }

    return res.json();
}

const getSession = ({cookie}) => {
	// console.log(document);

	// if(!cookie) return;

	// console.log(ctx);

	const {data, error} = useSWR(`/api/get-session`, fetcher);

	console.log(data, error ? Object.keys(error) : null);

	if(!data && !error) {
		// return getSession({cookie});
		return { session: { user: {} }, error, complete: false };
	} else if (error) {
		return { session: { user: null }, error: error.status, complete: false };
	}
	console.log('got session');
	return {session: data, error, complete: true};
}

export default function useSession (cookie) {
	// const session = useContext(SessionContext);

	// if(!cookie) return {session: null, error: null};

	// if(session === undefined) {
		return getSession({cookie});
	// }

	// return session;
}