import { useState, useEffect } from "react";
import Link from "next/link";
import useSession from "../../hooks/useSession.js";
import isAdmin from "../../hooks/isAdmin";
import NavItem from "./NavItem.js";
import NavDropdown from "./NavDropdown.js";
import Consent from "../Compliance/index.tsx";
import Cookies from "js-cookie";

const BarNav = () => {
  const [hovering, setHovering] = useState(false);
  const [cookieAcceptance, setCookieAcceptance] = useState(
    Cookies.get("priceErrorsCookie") || false
  );

  const { session } = useSession();

  const [adminUser, error] = isAdmin(session);

  console.log(
    Cookies.get("priceErrorsCookie"),
    !session.user,
    Cookies.get("priceErrorsCookie") || !session.user
  );

  const styles = {
    nav_items: {
      listStyle: "none",
      display: "flex",
    },
    button_transparent: {
      padding: "0.6rem 1.9rem",
      textTransform: "uppercase",
      border: "0.2rem solid #fff",
      cursor: "pointer",
      background: hovering ? "#fff" : "transparent",
      color: hovering ? "#cf37fe" : "#fff",
      marginTop: "-0.94rem",
      fontWeight: "bold",
      fontSize: "1rem",
      transition: "0.3s",
    },
    button_disabled: {
      padding: "0.6rem 1.9rem",
      textTransform: "uppercase",
      border: "0.2rem solid #CCC",
      cursor: "pointer",
      background: "transparent",
      color: "#CCC",
      marginTop: "-0.94rem",
      fontWeight: "bold",
      fontSize: "1rem",
      hover: "none",
    },
    link: {
      textDecoration: "none !important",
    },
  };

  return (
    <>
      <div className="nav">
        <ul style={styles.nav_items}>
          <NavItem
            onClick={() => {
              window.location = "/#HOME";
            }}
          >
            Home
          </NavItem>
          <NavItem
            onClick={() => {
              window.location = "/#HOME";
            }}
          >
            Purchase
          </NavItem>
          <NavItem
            onClick={() => {
              window.location = "/#FAQ";
            }}
          >
            FAQ
          </NavItem>
          <NavItem
            onClick={() => {
              window.location = "/#SOCIALS";
            }}
          >
            SOCIALS
          </NavItem>
          <NavItem
            onClick={() => {
              window.location = "/waitlist";
            }}
          >
            WAITLIST
          </NavItem>
          {!session.user ? (
            <NavItem>
              <button
                disabled={!cookieAcceptance}
                onMouseEnter={() => setHovering(true)}
                onMouseLeave={() => {
                  setHovering(false);
                }}
                onClick={() => {
                  window.location =
                    "https://hoc-api.apollodashboards.io/login/signin";
                }}
                style={
                  !cookieAcceptance
                    ? styles.button_disabled
                    : styles.button_transparent
                }
              >
                Login
              </button>
            </NavItem>
          ) : (
            <>
              {adminUser && (
                <NavDropdown titleLink="/admin" title={"Admin"}>
                  <a style={styles.link} href="/admin/checkouts">
                    <p>Checkouts</p>
                  </a>
                  <a style={styles.link} href="/admin/subscriptions">
                    <p>Subscriptions</p>
                  </a>
                </NavDropdown>
              )}
              <NavItem
                onClick={() => {
                  window.location = "/dashboard";
                }}
              >
                {/* <Link href="/dashboard"> */}
                Dashboard
                {/* </Link> */}
              </NavItem>
              <NavItem>
                <Link href="/api/logout">
                  <button
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => {
                      setHovering(false);
                    }}
                    style={styles.button_transparent}
                  >
                    Logout
                  </button>
                </Link>
              </NavItem>
            </>
          )}
        </ul>
      </div>
      {Cookies.get("priceErrorsCookie") ? null : (
        <Consent setCookieAcceptance={setCookieAcceptance} />
      )}
    </>
  );
};

export default BarNav;
