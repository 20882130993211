import { useState } from 'react';

const NavItem = (props) => {
	const [ hovering, setHovering ] = useState(false);

	const styles = {
		nav_item: {
			margin: '0rem 1.25rem',
			textTransform: 'uppercase',
			cursor: 'pointer',
			fontWeight: '600',
			fontSize: '1rem',
			color: hovering ? '#d6d6d6' : '#fff',
			transform: hovering ? 'scale(1.1)' : '',
			transition: '0.3s'
		}
	};

	return (
		<li
			onMouseEnter={() => setHovering(true)}
			onMouseLeave={() => {
				setHovering(false);
			}}
			onClick={props.onClick}
			style={styles.nav_item}
			className={props.className}
		>
			{props.children}
		</li>
	);
};

export default NavItem;
