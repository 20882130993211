import { useState } from 'react';
import useSession from '../../hooks/useSession';
import isAdmin from '../../hooks/isAdmin';

const BurgerMenu = () => {
    const [navbarOpen, setNavbarOpen] = useState(false);

    const { session } = useSession();

    const [adminUser, error] = isAdmin(session);

    const handleToggle = () => setNavbarOpen(!navbarOpen);

    const styles = {
        nav_hidden: {
            width: 0,
            display: 'none',
        },
        nav_shown: {
            listStyle: 'none',
            display: 'flex',
            overflowY: 'scroll',
            overflow: 'hidden',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            textTransform: 'uppercase',
            width: '100%',
        },
        navContainer: {
            flexDirection: 'column',
            display: 'flex',
            margin: '0rem 1.25rem',
			textTransform: 'uppercase',
			cursor: 'pointer',
			fontWeight: '600',
			fontSize: '1.5rem',
			color: '#fff',
			// transform: hovering ? 'scale(1.1)' : '',
			transition: '0.3s',
        },
        img: {
            filter: 'invert(100%)',
        },
        li: {
            padding: '4%',
        },
    }

    return <nav className="navbar" style={styles.navContainer}>
        <button onClick={handleToggle}><img style={styles.img} src="/menu.svg" /></button>
        {navbarOpen && <ul style={navbarOpen ? styles.nav_shown : styles.nav_hidden}>
            <li style={styles.li} onClick={() => {
						window.location = '/#HOME';
					}}>Home</li>
            <li style={styles.li} onClick={() => {
						window.location = '/#HOME';
					}}>Purchase</li>
            <li style={styles.li} onClick={() => {
						window.location = '/#FAQ';
					}}>FAQ</li>
            <li style={styles.li} onClick={() => {
						window.location = '/#SOCIALS';
					}}>Social</li>
            <li style={styles.li} onClick={() => {
                window.location = '/waitlist';
            }}>Waitlist</li>
            {!session.user ? (
                <li style={styles.li} onClick={() => {
                    window.location = 'https://hoc-api.apollodashboards.io/login/signin';
                }}>Login</li>
            ) : (
                <>
                    {adminUser && <li style={styles.li} onClick={() => {
                            window.location = '/admin'
                        }}>Admin</li>}
                    <li style={styles.li} onClick={() => {
							window.location = '/dashboard'
						}}>Dashboard</li>
                    <li style={styles.li} onClick={() => {
                        window.location = '/api/logout';
                    }}>Logout</li>
                </>
            )}
        </ul>}
    </nav>
};

export default BurgerMenu;