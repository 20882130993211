import useSWR from 'swr';

const fetcher = (url) => {
    return fetch(url).then(r => r.text());
}
const isAdmin = (session) => {
    const {data, error} = useSWR(`/api/admin/list`, fetcher);
    if (error) {
        return [null, error];
    }

    if (data == undefined) {
        return [null, null];
    }

    const adminList = JSON.parse(data);

    if (!session || !session.user) {
        return [null, null];
    }

    return [adminList.admins.includes(session.user.id), error];
};

export default isAdmin;