import BarTitle from './BarTitle.js';
import BarNav from './BarNav.js';
import BurgerMenu from './BurgerMenu.js';

const HeaderBar = () => {
	const styles = {
		bar: {
			display: 'flex',
			justifyContent: window.innerWidth <= 700 ? 'center' : 'space-between',
			position: 'relative',
			zIndex: 2,
			flexDirection: window.innerWidth <= 700 ? 'column' : '',
		}
	};

	return (
		<div className="header-bar" style={styles.bar}>
			<BarTitle />
			{window.innerWidth > 700 ? <BarNav /> : <BurgerMenu />}
		</div>
	);
};

export default HeaderBar;
