const BarTitle = () => {
	const styles = {
		image: {
			width: window.innerWidth <= 700 ? '12rem' : '9.4rem',
		},
		title: {
			display: 'flex',
			justifyContent: 'center',
		}
	};

	return (
		<div className="title" style={styles.title}>
			<a href="/">
				<img src="/title_logo.png" style={styles.image} className="title-logo" alt="Price Errors Logo" />
			</a>
		</div>
	);
};

export default BarTitle;
